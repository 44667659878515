* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 16px;
}

@font-face {
  font-family: "MS Sans Serif";
  src: url("../public/assets/fonts/ms-sans-serif.otf");
}

@font-face {
  font-family: "Merchant Copy";
  src: url("../public/assets/fonts/merchant-copy-doublesize-regular.ttf");
}

@font-face {
  font-family: "Arizona";
  src: url("../public/assets/fonts/ABCDiatype-Regular-Trial.woff");
}



.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
}

.video-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  z-index: 2;
  mix-blend-mode: overlay;
}

.container {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  overflow: scroll;
  padding: 2rem 0rem;
}

.content-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 620px;
  width: 55%;
  max-width: calc(100dvw - 2rem);
  height: 65%;
  max-height: calc(100dvh - 2rem);
  resize: both;
  overflow: hidden;
  margin: 0;
  border-top: 1px solid #DFDFDF;
  border-right: 1px solid #0A0A0A;
  border-bottom: 1px solid #0A0A0A;
  border-left: 1px solid #DFDFDF;
  background: #C6C6C6;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  padding: 0.25rem;
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.loaded {
  opacity: 1;
}

.closed {
  opacity: 0;
}

.header {
  display: flex;
  background: #080084;
  height: 42px;
  /* border-top: 1px solid #6F70A6;
  border-bottom: 1px solid #6F70A6;
  outline: 1px solid #c6c6c6; */
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.header:hover {
  cursor: move;
}

.header h1 {
  font-family: "Arizona";
  font-weight: 800;
  font-size: 1rem;
  text-align: center;
  color: #fefefe;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Arizona";
  font-size: 1rem;
  font-weight: 400;
  height: 16px;
  width: 16px;
  background: #c6c6c6;
  border-bottom: 1px solid #0A0A0A;
  border-left: 1px solid #DFDFDF;
  border-top: 1px solid #DFDFDF;
  border-right: 1px solid #0A0A0A;
  padding: 0.75rem;
}

.close:hover {
  cursor: pointer;
}

.pushed {
  border-top: 1px solid #848584 !important;
  border-right: 1px solid #FEFEFE !important;
  border-bottom: 1px solid #FEFEFE !important;
  border-left: 1px solid #848584 !important;
}


.menu {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  padding-left: 0rem;
  /* background: red; */
}

.menu ul {
  display: flex;
  justify-content: left;
  gap: 1rem;
}

.menu ul li {
  display: inline-block;
  font-family: "Arizona";
  font-weight: 400;
  font-size: 1rem;
  padding: 0.25rem;
  /* border-bottom: 1px solid #0A0A0A;
  border-left: 2px solid #DFDFDF;
  border-top: 2px solid #DFDFDF;
  border-right: 1px solid #0A0A0A; */
  transition: color ease-in-out 200ms;
}

.menu ul li:hover {
  cursor: pointer;
  color: white;
  /* border-bottom: 1px solid #0A0A0A;
  border-left: 2px solid #DFDFDF;
  border-top: 2px solid #DFDFDF;
  border-right: 1px solid #0A0A0A; */
}

.menu ul li.selected {
  /* border-top: 1px solid #0A0A0A;
  border-right: 1px solid #DFDFDF;
  border-bottom: 1px solid #DFDFDF;
  border-left: 1px solid #0A0A0A; */
  color: white;
}

.page-container {
  padding: 1rem 0rem;
  height: 100%;
  /* margin-bottom: 34.5px; */
  /* background: red; */
  border-top: 1px solid black;
  overflow: hidden;
}

.content-border {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top: 1px solid #848584;
  border-right: 1px solid #FEFEFE;
  border-bottom: 1px solid #FEFEFE;
  border-left: 1px solid #848584;
}

.content-border2 {
  position: relative;
  width: 100%;
  height: 100%;
  border-top: 1px solid #0A0A0A;
  border-right: 1px solid #DFDFDF;
  border-bottom: 1px solid #DFDFDF;
  border-left: 1px solid #0A0A0A;
  overflow: hidden;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0.25rem 2rem 0.25rem 0.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #e6e6e6;
  scrollbar-width: thin;
  scrollbar-color: #969696 #ffffff;
}


/* Customizing the scrollbar track */
.content::-webkit-scrollbar-track {
  background-color: #c6c6c6;
}

/* Customizing the scrollbar handle */
.content::-webkit-scrollbar-thumb {
  background-color: #969696;
}

/* Customizing the scrollbar width */
.content::-webkit-scrollbar {
  width: 0.6rem;
}

/* Hover effect on the scrollbar handle */
.content::-webkit-scrollbar-thumb:hover {
  background: #868686;
}

.content p {
  font-family: "Arizona";
  font-weight: 400;
  font-size: 0.9rem;
  text-align: justify;
  margin-bottom: 1rem;
}

.content p a {
  font-size: 0.9rem;
}

.services {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.service {
  display: flex;
  flex-direction: column;
}

.service-name {
  font-family: "Arizona";
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  display: block;
}

.service-description {
  font-family: "Arizona";
  font-weight: 400;
  font-size: 1rem;
}

.service-description ul {
  list-style-type: square;
}

.service-description ul li {
  font-size: 0.9rem;
  margin-left: 1.5rem;
}

.contact h2 {
  font-family: "Arizona";
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

/* .page {
  padding-right: 2rem;
} */

.staff {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
  justify-content: flex-start;
  overflow: hidden;
}

.staff img {
  width: 100%;
  border-top: 1px solid #848584;
  border-right: 1px solid #FEFEFE;
  border-bottom: 1px solid #FEFEFE;
  border-left: 1px solid #848584;
  /* image-rendering: pixelated; */
}

.person {
  display: block;
  font-size: 0.9rem;
  font-family: "Arizona";
}

.title {
  font-family: "Arizona";
  display: block;
  font-size: 0.9rem;
}

.title a {
  display: block;
  font-size: 0.9rem;
  padding-bottom: 0.5rem;
}

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #C6C6C6;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  border-top: 1px solid black;
}

.footer p {
  font-family: "Arizona";
  font-size: 0.7rem;
  line-height: 1rem;
}

.emoji {
  font-size: 0.8rem;
}

.logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
  z-index: 5;
}

.logo.hidden {
  opacity: 0;
  z-index: 0;
}

.logo img {
  height: 25%;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .container {
    justify-content: flex-start;
  }

  .content-container {
    width: 90%;
    max-width: calc(100dvw - 1rem);
    min-width: 300px;
    height: 90%;
    min-height: 360px;
    max-height: calc(100dvh - 1rem);
  }

  .content {
    padding: 0.25rem 1rem 0.25rem 0.5rem;
  }


  .content::-webkit-scrollbar-track {
    background-color: #c6c6c6;
  }


  .content::-webkit-scrollbar-thumb {
    background-color: #969696;
  }


  .content::-webkit-scrollbar {
    width: 0.65rem;
  }


  .content::-webkit-scrollbar-thumb:hover {
    background: #868686;
  }

  .staff {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    justify-content: flex-start;
  }

  .staff img {
    width: 100%;
  }

  .person {
    display: block;
    font-size: 0.9rem;
  }

  .title {
    display: block;
    font-size: 0.9rem;
  }

  .title a {
    font-size: 0.9rem;
  }
}